/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    patientLogout,
    patientRegistration,
    patientSessionVerification,
    patientLogin,
    patientAccountVerification,
    patientRecoverAccount,
    patientVerifyAccountRecoveryToken,
    patientResetAccountPassword,
} from '../../fetch/auth';
import {
    PatientRegistration,
    LoginRequest,
    VerifyAccountRequest,
    RecoverAccountRequest,
    VerifyRecoveryTokenResponse,
    ResetPassword,
} from '../../../../api/types/auth';
import { setSnackbarMessage } from '../event';
import { SnackbarTypes } from '../../types';
import { FetchError, handleError } from '../../tools/fetch';
import { PatientResource } from '../../../../api/types/user/patient';
import { captureMessage } from '../../tools/sentry';
import { ConsultationRoutes } from '../../../../api/routes/consultation';
import { HttpStatus } from '../../../../api/utils/https';

export const patientLoginThunk = createAsyncThunk<
    PatientResource,
    {
        body: LoginRequest;
        redirectUrl?: string | null;
    },
    { state: RootState }
>('patient/sign-in', async ({ body, redirectUrl }, { dispatch, rejectWithValue }) => {
    try {
        const user = await patientLogin(body);
        // Redirect the user.
        if (redirectUrl != null) {
            window.location.href = decodeURIComponent(redirectUrl);
        }
        return user;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    "We encountered an issue and couldn't complete your login request. Please try again later.",
            }),
        );

        return rejectWithValue(response?.reason);
    }
});

export const patientRegistrationThunk = createAsyncThunk<PatientResource, PatientRegistration>(
    'patient/register',
    async (credentials, { dispatch, rejectWithValue }) => {
        try {
            const user = await patientRegistration(credentials);
            return user;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        "We encountered an issue and couldn't complete your registration request. Please try again later.",
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const patientRecoverAccountThunk = createAsyncThunk<boolean, RecoverAccountRequest>(
    'patient/recover/account',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            await patientRecoverAccount(body);
            return true;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        "We encountered an issue and couldn't complete your account recovery request. Please try again.",
                }),
            );

            return rejectWithValue(false);
        }
    },
);

export const verifyAccountRecoveryTokenThunk = createAsyncThunk<
    VerifyRecoveryTokenResponse,
    {
        token: string;
        verificationToken: string;
    }
>('patient/verify/account/recovery/token', async ({ token, verificationToken }, { dispatch, rejectWithValue }) => {
    try {
        return await patientVerifyAccountRecoveryToken(token, verificationToken);
    } catch (error) {
        const response = await handleError(error as FetchError);

        if (response && response.status === HttpStatus.BAD_REQUEST) {
            window.location.href = `/${ConsultationRoutes.Auth}/`;
        }

        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    "We encountered an issue and couldn't complete your account request to reset your account password. Please try again.",
            }),
        );

        return rejectWithValue(false);
    }
});

export const changeAccountPasswordThunk = createAsyncThunk<
    void,
    {
        email: string;
        token: string;
        body: ResetPassword;
        verificationToken: string;
    }
>(
    'patient/verify/account/recovery/token',
    async ({ token, verificationToken, body }, { dispatch, rejectWithValue }) => {
        try {
            await patientResetAccountPassword(token, verificationToken, body);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.SUCCESS,
                    message:
                        'You have successfully changed your account password. You can now log in with the new password.',
                }),
            );

            window.location.href = `/${ConsultationRoutes.Auth}/`;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        "We encountered an issue and couldn't complete your account request to change your account password. Please try again.",
                }),
            );

            return rejectWithValue(false);
        }
    },
);

export const verificationPatientAccountThunk = createAsyncThunk<void, VerifyAccountRequest>(
    'patient/account/verification',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            await patientAccountVerification(body);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        "We encountered an issue and couldn't verify your account. Please try again later.",
                }),
            );
            return rejectWithValue('Account verification failed');
        }
    },
);

export const verifyPatientSessionThunk = createAsyncThunk<PatientResource, void>(
    'patient/verify/session',
    async (_, { rejectWithValue }) => {
        try {
            return await patientSessionVerification();
        } catch (error) {
            const response = await handleError(error as FetchError);
            return rejectWithValue(response?.reason ?? 'Session verification failed.');
        }
    },
);

export const patientLogoutThunk = createAsyncThunk<void, void>('patient/logout', async () => {
    try {
        localStorage.clear();
        sessionStorage.clear();
        await patientLogout();
        window.location.href = process.env.PLATFORM_APP_HOST ?? 'https://aussiscripts.com.au';
    } catch (error) {
        // eslint-disable-next-line no-console
        captureMessage(`[PATIENT LOGOUT FAILED] Failed to complete patient logout request. Error: ${error}`);
    }
});
