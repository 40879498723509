/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import 'animate.css';
import { FC, Suspense, lazy, useEffect } from 'react';
import { gtag, initDataLayer, install } from 'ga-gtag';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ErrorBoundary } from './error';
import { AppDispatch, history, persistor, store } from './store';
import { GlobalStyle } from './style/global';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarRenderer } from './components/shared/SnackbarRenderer';
import { Navigation } from './components/shared/Navigation';
import { StyledMainContainer } from './style';
import { FullScreenLoadingPlaceholder } from './components/shared/FullScreenPlaceholder';
import { getPlatformDetailsThunk } from './redux/platform/thunk';
import { verifyPatientSessionThunk } from './redux/patient/thunks';
import {
    selectIsPatientAccountVerified,
    selectIsUserAuthorized,
    selectIstVerifyUserSessionEvent,
} from './redux/patient/selectors';
import { ConsultationRoutes } from '../../api/routes/consultation';
import { ProtectedRouteProps } from './types';
import { ThemeProvider } from 'styled-components';
import { selectHasInitializedTracking, selectThemeType } from './redux/platform/selector';
import { DarkTheme, LightTheme, ThemeType } from '../../api/constants';

const Auth = lazy(() => import('./components/Auth'));
const Patient = lazy(() => import('./components/Patient'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const StoreApp: FC = () => {
    const themeType = useSelector(selectThemeType);

    const dispatch = useDispatch<AppDispatch>();

    const location = useLocation();
    const hasInitializedTracking = useSelector(selectHasInitializedTracking);

    // Check if the user is authenticated and verified.
    const isAuthenticated = useSelector(selectIsUserAuthorized);
    const isPatientAccountVerified = useSelector(selectIsPatientAccountVerified);

    // Check the user account verification status.
    const isVerifyingPatient = useSelector(selectIstVerifyUserSessionEvent);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        dispatch(verifyPatientSessionThunk());
        dispatch(getPlatformDetailsThunk());

        // Google tag setup
        if (process.env.NODE_ENV === 'production') {
            initDataLayer();

            gtag('consent', 'default', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted',
            });

            install('G-7ZB3890GKJ');
        }
    }, []);

    // Dashboard route guard. Redirects to the auth page if the user is not authenticated.
    const ProtectedDashboardRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
        return isAuthenticated && isPatientAccountVerified ? element : <Navigate to={`/${ConsultationRoutes.Auth}`} />;
    };

    // Auth route guard. Redirects to the dashboard page if the user is authenticated.
    const ProtectedAuthRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
        return !isAuthenticated || !isPatientAccountVerified ? (
            element
        ) : (
            <Navigate to={`/${ConsultationRoutes.Patient}`} />
        );
    };

    return (
        <ThemeProvider theme={themeType === ThemeType.LightTheme ? LightTheme : DarkTheme}>
            <GlobalStyle />

            <Suspense fallback={<FullScreenLoadingPlaceholder />}>
                {isVerifyingPatient ? (
                    <FullScreenLoadingPlaceholder />
                ) : (
                    <>
                        <Navigation />
                        <StyledMainContainer>
                            <Routes>
                                <Route
                                    path={`/${ConsultationRoutes.Auth}/*`}
                                    element={<ProtectedAuthRoute element={<Auth />} />}
                                />
                                <Route
                                    path={`/${ConsultationRoutes.Patient}/*`}
                                    element={<ProtectedDashboardRoute element={<Patient />} />}
                                />
                                <Route index element={<Navigate to={`/${ConsultationRoutes.Patient}`} />} />
                                <Route path="*" element={<Navigate to={`/${ConsultationRoutes.Patient}`} />} />
                            </Routes>
                        </StyledMainContainer>
                    </>
                )}

                <SnackbarRenderer />
            </Suspense>
        </ThemeProvider>
    );
};

const App: FC = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <Router history={history}>
                    <PersistGate loading={null} persistor={persistor}></PersistGate>
                    <StoreApp />
                </Router>
            </Provider>
        </LocalizationProvider>
    );
};

root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
);
