/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import * as r from 'runtypes';
import { PaymentMerchant } from '../constants/checkout';
import {
    MedicalCertificateServiceTitleSchema,
    MedicalHealthServiceSchema,
    NicotineVapingServiceTitleSchema,
    PathologyServiceTitleSchema,
    PrescriptionServiceTitleSchema,
    SpecialistReferralServiceTitleSchema,
    TelehealthServiceTitleSchema,
    WeightManagementServiceTitleSchema,
} from '../constants';
import { MedicalRequestCheckoutSchema } from './medical/request';
import { SkincareServiceTitleSchema } from '../constants/services/skincare';

export const ServiceTitleSchema = PathologyServiceTitleSchema.Or(SpecialistReferralServiceTitleSchema)
    .Or(TelehealthServiceTitleSchema)
    .Or(MedicalCertificateServiceTitleSchema)
    .Or(PrescriptionServiceTitleSchema)
    .Or(NicotineVapingServiceTitleSchema)
    .Or(SkincareServiceTitleSchema)
    .Or(MedicalHealthServiceSchema)
    .Or(WeightManagementServiceTitleSchema);
export type ServiceTitle = r.Static<typeof ServiceTitleSchema>;

export const StripePaymentSchema = r.Record({
    token: r.String,
    type: r.Literal(PaymentMerchant.Stripe),
});
export type StripePayment = r.Static<typeof StripePaymentSchema>;

const PaypalPaymentSchema = r.Record({
    order_id: r.String,
    facilitator_access_token: r.String,
    type: r.Literal(PaymentMerchant.Stripe),
});

export const ConsultationRequestSchema = r.Record({
    payment: PaypalPaymentSchema.Or(StripePaymentSchema),
});

export const ClientSecretRequestSchema = r.Record({
    service: ServiceTitleSchema,
});
export type ClientSecretRequest = r.Static<typeof ClientSecretRequestSchema>;

export const ClientSecretResponseSchema = r.Record({
    price: r.Number,
    clientSecretKey: r.String,
});
export type ClientSecretResponse = r.Static<typeof ClientSecretResponseSchema>;

export const StripePaymentCheckoutSchema = r
    .Record({
        payment: StripePaymentSchema,
        requestUploadReferenceFiles: r.Array(r.String),
    })
    .extend(MedicalRequestCheckoutSchema.fields);
export type StripeCheckoutPayment = r.Static<typeof StripePaymentCheckoutSchema>;

export const PaypalOrderRequestSchema = r.Record({
    service: ServiceTitleSchema,
});
export type PaypalOrderRequest = r.Static<typeof PaypalOrderRequestSchema>;

export const PaypalOrderResponseSchema = r.Record({
    price: r.Number,
    orderId: r.String,
});
export type PaypalOrderResponse = r.Static<typeof PaypalOrderResponseSchema>;

export const PaypalPaymentCheckoutSchema = r
    .Record({
        orderId: r.String,
        requestUploadReferenceFiles: r.Array(r.String),
    })
    .extend(MedicalRequestCheckoutSchema.fields);
export type PaypalCheckoutPayment = r.Static<typeof PaypalPaymentCheckoutSchema>;
