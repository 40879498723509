/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import {
    ClientSecretRequestSchema,
    ClientSecretResponseSchema,
    PaypalOrderRequestSchema,
    PaypalOrderResponseSchema,
    PaypalPaymentCheckoutSchema,
    StripePaymentCheckoutSchema,
} from '../types/checkout';
import { PatientMedicalRequestSchema } from '../types/medical/request';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const GetStripeClientSecretKeyEndpoint = new EndpointSync({
    path: '/stripe/secret',
    method: HttpMethod.POST,
    requestValidator: ClientSecretRequestSchema,
    responseValidator: ClientSecretResponseSchema,
});

export const StripeCheckoutRequestEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/stripe/payment',
    requestValidator: StripePaymentCheckoutSchema,
    responseValidator: PatientMedicalRequestSchema,
});

export const CreatePaypalOrderEndpoint = new EndpointSync({
    path: '/paypal/create-order',
    method: HttpMethod.POST,
    requestValidator: PaypalOrderRequestSchema,
    responseValidator: PaypalOrderResponseSchema,
});

export const PaypalCheckoutRequestEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/paypal/payment',
    requestValidator: PaypalPaymentCheckoutSchema,
    responseValidator: PatientMedicalRequestSchema,
});
