/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { CHECKOUT_MANAGER_PATH } from '../../../api/endpoints';
import {
    CreatePaypalOrderEndpoint,
    GetStripeClientSecretKeyEndpoint,
    PaypalCheckoutRequestEndpoint,
    StripeCheckoutRequestEndpoint,
} from '../../../api/endpoints/checkout';
import { ClientSecretRequest, PaypalCheckoutPayment, StripeCheckoutPayment } from '../../../api/types/checkout';
import { endpointFetchWithRetries, endpointFetch } from '../tools/fetch';

export function getStripeClientSecretKey(body: ClientSecretRequest) {
    return endpointFetchWithRetries(GetStripeClientSecretKeyEndpoint, {
        scope: CHECKOUT_MANAGER_PATH,
        redirect: false,
        body,
    });
}

export function getPaypalOrderId(body: ClientSecretRequest) {
    return endpointFetchWithRetries(CreatePaypalOrderEndpoint, {
        scope: CHECKOUT_MANAGER_PATH,
        redirect: false,
        body,
    });
}

export function onCompleteStripeCheckout(body: StripeCheckoutPayment) {
    return endpointFetch(StripeCheckoutRequestEndpoint, {
        scope: CHECKOUT_MANAGER_PATH,
        redirect: false,
        body,
    });
}

export function onCompletePaypalCheckout(body: PaypalCheckoutPayment) {
    return endpointFetch(PaypalCheckoutRequestEndpoint, {
        scope: CHECKOUT_MANAGER_PATH,
        redirect: false,
        body,
    });
}
