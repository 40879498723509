/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { PLATFORM_MANAGER_PATH } from '../../../api/endpoints';
import { GetPlatformDetailsEndpoint } from '../../../api/endpoints/platform';
import { endpointFetchWithRetries } from '../tools/fetch';

export function getPlatformDetails() {
    return endpointFetchWithRetries(GetPlatformDetailsEndpoint, {
        scope: PLATFORM_MANAGER_PATH,
        redirect: false,
    });
}
