/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { FC, useMemo } from 'react';
import {
    StyledAuthModeHelper,
    StyledNavigation,
    StyledNavigationLogo,
    StyledNavigationMenu,
    StyledNavigationMenus,
    StyledNavigationOptions,
    StyledNavigationWrapper,
} from './style';
import { ExpressScriptsLogo } from '../../../resources/express_scripts/logo';
import { UserNavigation } from './UserNavigation';
import { useSelector } from 'react-redux';
import { selectIsUserAuthorized } from '../../../redux/patient/selectors';
import { ConsultationRoutes, PatientDashboardRoutes } from '../../../../../api/routes/consultation';
import { IconUserPlus, IconLogin2 } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { AuthRoutes } from '../../Auth/routes';
import { AnchorButton } from '../Buttons/Anchor';
import { ButtonType } from '../Buttons/style';

export const Navigation: FC = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const isAuthorized = useSelector(selectIsUserAuthorized);

    // Check if we are on login page.
    const isLoginIn = useMemo(
        () =>
            currentPath === `/${ConsultationRoutes.Auth}` ||
            currentPath === `/${ConsultationRoutes.Auth}/${AuthRoutes.Login}`,
        [currentPath],
    );

    // Check if we are on registration page.
    const isRegistering = useMemo(() => currentPath === `/${ConsultationRoutes.Auth}/${AuthRoutes.Register}`, [
        currentPath,
    ]);

    return (
        <StyledNavigation isAuthorized={isAuthorized}>
            <StyledNavigationWrapper>
                <StyledNavigationLogo to={isAuthorized ? '' : process.env.PLATFORM_APP_HOST ?? ''}>
                    <ExpressScriptsLogo />
                </StyledNavigationLogo>
                {isAuthorized && (
                    <>
                        <StyledNavigationMenus>
                            <StyledNavigationMenu to={`${ConsultationRoutes.Patient}`} end>
                                Home
                            </StyledNavigationMenu>
                            <StyledNavigationMenu
                                to={`${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Telehealth}`}
                            >
                                Telehealth
                            </StyledNavigationMenu>
                            <StyledNavigationMenu
                                to={`${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Prescriptions}`}
                            >
                                Prescriptions
                            </StyledNavigationMenu>
                            <StyledNavigationMenu
                                to={`${ConsultationRoutes.Patient}/${PatientDashboardRoutes.WeightManagementConsultation}`}
                            >
                                Weight Loss
                            </StyledNavigationMenu>
                            <StyledNavigationMenu
                                to={`${ConsultationRoutes.Patient}/${PatientDashboardRoutes.MedicalCertificates}`}
                            >
                                Medical Certificates
                            </StyledNavigationMenu>
                            <StyledNavigationMenu
                                to={`${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Pathology}`}
                            >
                                Blood Tests
                            </StyledNavigationMenu>
                            <StyledNavigationMenu
                                to={`${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Referrals}`}
                            >
                                Referrals
                            </StyledNavigationMenu>
                        </StyledNavigationMenus>

                        <StyledNavigationOptions>
                            <UserNavigation />
                        </StyledNavigationOptions>
                    </>
                )}

                {isLoginIn && (
                    <StyledAuthModeHelper>
                        <AnchorButton
                            variation={ButtonType.Primary}
                            path={`/${ConsultationRoutes.Auth}/${AuthRoutes.Register}`}
                        >
                            <span>Create Account</span>
                            <IconUserPlus />
                        </AnchorButton>
                    </StyledAuthModeHelper>
                )}

                {isRegistering && (
                    <StyledAuthModeHelper>
                        <AnchorButton
                            variation={ButtonType.Primary}
                            path={`/${ConsultationRoutes.Auth}/${AuthRoutes.Login}`}
                        >
                            <span>Login</span>
                            <IconLogin2 />
                        </AnchorButton>
                    </StyledAuthModeHelper>
                )}
            </StyledNavigationWrapper>
        </StyledNavigation>
    );
};
