/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const selectEventState = (state: RootState) => state.event;

export const selectEventRequest = (eventId: string) =>
    createSelector([selectEventState], ({ requests }) => {
        return requests[eventId];
    });

export const selectSnackbarEvent = (state: RootState) => state.event.snackbar;
