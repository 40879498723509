/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { RootState } from '../../store';

export const selectUserState = (state: RootState) => state.user;

export const selectHasAuthEvent = (state: RootState) => state.user.hasAuthenticationEvent;

export const selectHasAccountVerificationEvent = (state: RootState) => state.user.hasAccountVerificationEvent;

export const selectIstVerifyUserSessionEvent = (state: RootState) => state.user.isVerifyingSessionEvent;

export const selectHasCompletedOnboarding = (state: RootState) => state.user.account?.completed_onboarding ?? false;

export const selectUserAvatar = ({ user }: RootState) => user.account?.avatar ?? undefined;

export const selectIsUserVerified = ({ user }: RootState) => user.account != null && user.account.verified;

export const selectIsUserAuthorized = (state: RootState) => state.user.account != null;

export const selectIsPatientAccountVerified = (state: RootState) => state.user.account?.verified ?? false;

export const selectIsAccountReady = ({ user }: RootState) => user?.account != null;

export const selectUserEmail = (state: RootState) => state.user.account?.email ?? '';

export const selectUserFullName = (state: RootState) => state.user.fullName;

export const selectUserNameInitials = (state: RootState) => state.user.initials;

export const selectIsRecoveringAccountEvent = (state: RootState) => state.user.isRecoveringAccountEvent;

export const selectRecoveryTokenResponse = (state: RootState) => state.user.recoveryTokenResponse;

export const selectUserId = (state: RootState) => state.user?.account?.id;

export const selectUserInitials = (state: RootState) =>
    `${state.user?.account?.first_name[0] ?? 'E'}${state.user?.account?.last_name[0] ?? 'S'}`.toUpperCase();
