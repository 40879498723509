/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { GetPatientMedicalRequestsSchema } from '../../types/medical/request';
import { EndpointSync, HttpMethod } from '../../utils/endpointSync';

export const GetMedicalRequestsEndpoint = new EndpointSync({
    path: '/',
    method: HttpMethod.GET,
    responseValidator: GetPatientMedicalRequestsSchema,
});
