/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import * as r from 'runtypes';
import { ServiceTitleSchema } from './checkout';
import { maybe } from '../utils/runtypes';

const DocumentVerificationDetail = r.Record({
    label: r.String,
    value: r.String,
});
export type DocumentVerificationDetail = r.Static<typeof DocumentVerificationDetail>;

export const DocumentVerificationSchema = r.Record({
    valid: r.Boolean,
    documentId: r.String,
    title: maybe(ServiceTitleSchema),
    details: maybe(r.Array(DocumentVerificationDetail)),
});

export type DocumentVerification = r.Static<typeof DocumentVerificationSchema>;
