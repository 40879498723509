/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PlatformDetails } from '../../../../api/types/platform';
import { getPlatformDetails } from '../../fetch/platform';

// TODO: Remove this code block.
export const getPlatformDetailsThunk = createAsyncThunk<PlatformDetails, void>('platform/details', async (_) => {
    const details = await getPlatformDetails();
    return details;
});
