/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import styled, { css } from 'styled-components';
import { flamingo, mountainMeadow, wildSand, zircon } from '../../../style/color';
import { NAVIGATION_BAR_HEIGHT, flexContainerStyle } from '../../../style';
import { NavLink } from 'react-router-dom';
import { StyledAnchorButton } from '../Buttons/Anchor/style';

export const StyledNavigation = styled.nav<{ isAuthorized: boolean }>`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    position: fixed;
    background: ${({ theme }) => theme.BrandWhite};

    ${({ isAuthorized }) =>
        isAuthorized &&
        css`
            border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};
        `}

    @media (max-height: 740px) {
        border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};
    }

    @media (max-width: 640px) {
        border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};
    }
`;

export const StyledNavigationLogo = styled(NavLink)`
    position: relative;
    svg {
        height: 32px;
        color: ${({ theme }) => theme.BrandPrimary};
    }
`;

export const StyledNavigationWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding: 0 24px;
    max-width: 1440px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: ${NAVIGATION_BAR_HEIGHT}px;

    @media (max-width: 540px) {
        ${StyledNavigationLogo} svg {
            height: 26px;
        }
    }

    @media (max-width: 480px) {
        padding: 0 16px;
    }
`;

export const StyledNavigationMenu = styled(NavLink)`
    padding: 0 8px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    line-height: 20px;
    transition: color 0.25s;
    color: ${({ theme }) => theme.TextSecondary};

    &:hover {
        color: ${({ theme }) => theme.TextPrimary};
    }

    &.active {
        color: ${({ theme }) => theme.BrandPrimary};
    }
`;

export const StyledNavigationMenus = styled.div`
    gap: 16px;
    display: flex;
    margin-left: 64px;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1240px) {
        margin-left: 46px;
    }

    @media (max-width: 1146px) {
        display: none;
    }
`;

export const StyledNavigationOptions = styled.div`
    gap: 16px;
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${StyledAnchorButton} {
        height: 46px;
        text-transform: capitalize;
    }

    @media (max-width: 520px) {
        ${StyledAnchorButton} {
            gap: 8px;
            height: 36px;
            padding: 0 14px;
        }
    }

    @media (max-width: 460px) {
        ${StyledAnchorButton} {
            padding: 0 10px;
        }
    }
`;

export const StyledMobileNavigation = styled.button`
    gap: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    outline: none;
    background: transparent;

    transition: al 0.25s;
    color: ${({ theme }) => theme.TextSecondary};

    .MuiButtonBase-root {
        margin-left: 0 !important;
    }
`;

const navAccountButtonStyle = css`
    width: 100%;
    height: 40px;
    display: block;
    padding: 0 24px;
    position: relative;
    border-radius: 4px;
    background: ${({ theme }) => theme.BrandWhite};
    color: ${({ theme }) => theme.TextSecondary};

    gap: 16px;
    ${flexContainerStyle('row', 'flex-start', 'center')}

    svg {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }

    &:hover {
        color: ${({ theme }) => theme.BrandPrimary};
    }

    span {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const StyledDashboardSideBar = styled.div`
    top: 0;
    left: 0;
    z-index: 90;
    width: 255px;
    height: 100vh;
    position: fixed;
    background: ${({ theme }) => theme.BrandWhite};
    border-right: 1px solid ${({ theme }) => theme.BorderPrimary};
`;

export const StyledDashboardTopBar = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    position: fixed;
    background: ${({ theme }) => theme.BrandWhite};
    height: ${NAVIGATION_BAR_HEIGHT}px;
    border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};
`;

export const StyledDashboardTopBarWrapper = styled.div`
    height: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledDashboardNavigation = styled.nav`
    position: relative;

    @media (max-width: 1240px) {
        ${StyledDashboardSideBar} {
            width: 220px;
        }
    }

    @media (max-width: 980px) {
        ${StyledDashboardSideBar} {
            display: none;
        }
    }
`;

export const StyledDashboardNavigationLogoWrapper = styled.div`
    position: relative;

    svg {
        height: 30px;
    }
`;

export const StyledDashboardNavigationWrapper = styled.div`
    width: 100%;
    padding: 120px 24px 24px 24px;
`;

export const StyledDashboardNavigationGroup = styled.div`
    position: relative;

    &:not(:last-child) {
        margin-bottom: 40px;
    }
`;

export const StyledDashboardNavigationTitle = styled.div`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.TextSecondary};
`;

export const StyledDashboardNavigationEntry = styled(NavLink)`
    width: 100%;
    height: 56px;
    padding: 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    transition: all 0.25s;
    border: 1px solid ${wildSand};
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.active {
        color: ${({ theme }) => theme.BrandWhite};
        background: ${({ theme }) => theme.BrandPrimary};
    }

    &:not(.active) {
        color: ${({ theme }) => theme.TextSecondary};
        background: transparent;

        &:hover {
            border-color: ${({ theme }) => theme.BorderPrimary};
        }
    }

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    svg {
        width: 22px;
        height: 22px;
        margin-right: 14px;
    }
`;

export const StyledNavigationTopBarOptions = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const StyledUserAvatar = styled.button`
    width: 43px;
    height: 43px;
    cursor: pointer;
    position: relative;
    border-radius: 40px;
    background: ${({ theme }) => theme.BrandPrimary};

    outline: none;
    border: 2px solid ${({ theme }) => theme.BorderPrimary};

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::after {
        width: 8px;
        height: 8px;
        content: '';
        display: block;
        border-radius: 8px;
        border: 2px solid ${zircon};
        background: ${mountainMeadow};

        right: -1px;
        bottom: -1px;
        position: absolute;
    }
`;

export const StyledUserMenuHeader = styled.div`
    gap: 12px;
    height: 70px;
    padding: 0 16px;
    position: relative;
    ${flexContainerStyle('row', 'flex-start', 'center')}
`;

export const StyledUserMenuHeaderDetails = styled.div`
    gap: 4px;
    height: 70px;
    position: relative;
    ${flexContainerStyle('column', 'center', 'center')}
`;

export const StyledUserMenuBody = styled.div`
    position: relative;
`;

export const StyledUserMenuName = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.TextPrimary};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
`;

export const StyledUserMenuEmail = styled.span`
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.TextSecondary};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
`;

export const StyledUserMenuContainer = styled.div`
    padding: 10px 0;
    min-width: 260px;
    max-width: 260px;
    position: relative;
    background: ${({ theme }) => theme.BrandWhite};
`;

export const StyledAccountMenuButton = styled.button`
    ${navAccountButtonStyle};

    border: none;
    outline: none;
    color: ${flamingo};
`;

export const StyledAccountNavButton = styled(NavLink)`
    ${navAccountButtonStyle};
`;

export const StyledAccountNavDivider = styled.div`
    height: 0;
    margin: 5px 0;
    overflow: hidden;
    border-top: 1px solid ${({ theme }) => theme.BorderPrimary};
`;

export const StyledAuthModeHelper = styled.div`
    margin-left: auto;

    ${StyledAnchorButton} {
        height: 44px;
        padding: 0 16px;
    }

    @media (max-height: 370px) {
        svg {
            display: none;
        }
    }
`;
