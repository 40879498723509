/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { SubmitContactFormSchema } from '../types/platform';
import { DocumentVerificationSchema } from '../types/verification';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const SubmitContactFormEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/submit/contact/form',
    requestValidator: SubmitContactFormSchema,
});

export const DocumentVerificationEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/document/verification/:documentCode',
    responseValidator: DocumentVerificationSchema,
});
