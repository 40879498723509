/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { MEDICAL_PROFILE_MANAGER_PATH } from '../../../../api/endpoints';
import {
    CreateMedicalProfileEndpoint,
    CreateOnboardingMedicalProfileEndpoint,
    DeleteMedicalProfileEndpoint,
    GetMedicalProfilesEndpoint,
    UpdateMedicalProfileEndpoint,
} from '../../../../api/endpoints/medical/profile';
import { MedicalProfile } from '../../../../api/types/medical/profile';
import { endpointFetchWithRetries, endpointFetch } from '../../tools/fetch';

export function getMedicalProfiles() {
    return endpointFetchWithRetries(GetMedicalProfilesEndpoint, { scope: MEDICAL_PROFILE_MANAGER_PATH });
}

export function createFamilyMedicalProfile(body: MedicalProfile) {
    return endpointFetch(CreateMedicalProfileEndpoint, { scope: MEDICAL_PROFILE_MANAGER_PATH, body });
}

export function createOnboardingMedicalProfile(body: MedicalProfile) {
    return endpointFetch(CreateOnboardingMedicalProfileEndpoint, { scope: MEDICAL_PROFILE_MANAGER_PATH, body });
}

export function updateMedicalProfile(profileId: string, body: MedicalProfile) {
    return endpointFetch(UpdateMedicalProfileEndpoint, {
        scope: MEDICAL_PROFILE_MANAGER_PATH,
        body,
        params: {
            medical_profile_id: profileId,
        },
    });
}

export function deleteMedicalProfile(profileId: string) {
    return endpointFetch(DeleteMedicalProfileEndpoint, {
        scope: MEDICAL_PROFILE_MANAGER_PATH,
        params: {
            medical_profile_id: profileId,
        },
    });
}
