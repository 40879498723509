/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PRESCRIPTION_MANAGER_PATH } from '../../../api/endpoints';
import {
    GetPrescriptionMedicineConsultationEndpoint,
    GetConsultPrescriptionFamiliesEndpoint,
    GetConsultPrescriptionFamilyMedicineEndpoint,
    SearchPrescriptionMedicineEndpoint,
} from '../../../api/endpoints/prescription';
import { endpointFetchWithRetries } from '../tools/fetch';

export function getPrescriptionFamilies() {
    return endpointFetchWithRetries(GetConsultPrescriptionFamiliesEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        redirect: false,
    });
}

export function searchPrescriptionMedicine(searchParameter: string, medicineFamily?: string) {
    return endpointFetchWithRetries(SearchPrescriptionMedicineEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        redirect: false,
        query: {
            searchParameter,
            ...(medicineFamily != null ? { medicineFamily } : {}),
        },
    });
}

export function getPrescriptionFamilyMedicine(prescriptionFamilyId: string) {
    return endpointFetchWithRetries(GetConsultPrescriptionFamilyMedicineEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        redirect: false,
        params: {
            prescriptionFamilyId,
        },
    });
}

export function getPrescriptionDigitalConsultation(prescriptionMedicineId: string) {
    return endpointFetchWithRetries(GetPrescriptionMedicineConsultationEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        redirect: false,
        params: {
            prescriptionMedicineId,
        },
    });
}
