/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { buttercup, zircon } from './color';
import { StyledHeadingFour, StyledHeadingTwo } from '../components/shared/Heading/style';
import { StyledAnchorButton } from '../components/shared/Buttons/Anchor/style';
import { SnackbarTypes } from '../types';

// Shared view max-width for containers.
export const VIEW_CONTAINER_MAX_WIDTH = 1240;

export const NAVIGATION_BAR_HEIGHT = 70;

const StyledOverlay = styled.div`
    background: rgba(0, 0, 0, 0.6);
`;

/**
 * Returns css styling for a flex container.
 *
 * @param direction set the direction of the flexible items inside the container
 * @param justify property to align the items horizontally
 * @param align property to align the items vertically
 * @returns flex css styling properties
 */
export const flexContainerStyle = (
    direction: 'row' | 'column' | 'row-reverse' | 'column-reverse' | 'initial' | 'inherit',
    justify:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'initial'
        | 'inherit',
    align:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'stretch'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'initial'
        | 'inherit',
) => css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
`;

export const StyledButtonGroup = styled.div`
    ${flexContainerStyle('row', 'center', 'center')}

    a {
        width: calc(50% - 12px);

        &:not(:last-child) {
            margin-right: auto;
        }
    }
`;

export const StyledMainContainer = styled.main`
    position: relative;
    padding-top: ${NAVIGATION_BAR_HEIGHT}px;
    min-height: calc(100vh - ${NAVIGATION_BAR_HEIGHT}px);
    background: ${({ theme }) => theme.BackgroundPrimary};
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding: 0 24px;
    position: relative;
    max-width: ${VIEW_CONTAINER_MAX_WIDTH}px;

    @media (max-width: 480px) {
        padding: 0 16px;
    }
`;

export const StyledDashboardContentWrapper = styled(StyledContentWrapper)`
    padding-top: ${NAVIGATION_BAR_HEIGHT}px;
    padding-bottom: ${NAVIGATION_BAR_HEIGHT}px;

    @media (max-width: 1030px) {
        padding-top: 36px;
    }

    @media (max-width: 480px) {
        padding-top: 24px;
    }
`;

export const StyledTableContentWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding: 0 24px;
    position: relative;
    max-width: ${VIEW_CONTAINER_MAX_WIDTH}px;
`;

export const StyledReaderContentWrapper = styled(StyledContentWrapper)`
    max-width: 800px;
    padding-bottom: 64px;
`;

export const StyledSectionContainer = styled.section<{ highlight?: boolean; minified?: boolean }>`
    padding: 100px 0;
    position: relative;

    ${StyledContentWrapper}:not(:last-child) {
        margin-bottom: 60px;
    }

    ${({ highlight }) =>
        highlight &&
        css`
            background: ${zircon};
        `}

    ${({ minified }) =>
        minified &&
        css`
            padding: 64px 0;
        `}

    @media (max-width: 640px) {
        padding: 36px 0;
    }
`;

export const StyledNavLink = styled(NavLink)`
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.TextPrimary};
    transition: all 0.25s;
    text-transform: uppercase;

    &:hover {
        color: ${({ theme }) => theme.BrandPrimary};
    }
`;

export const StyledProductsListLoader = styled.div<{ isLoadingAll: boolean }>`
    height: ${({ isLoadingAll }) => (isLoadingAll ? '40vh' : '100px')};
    ${flexContainerStyle('row', 'center', 'center')}
`;

export const StyledFullScreenOverlay = styled(StyledOverlay)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: center;
`;

export const StyledLongCopy = styled.p`
    font-size: 16px;
    max-width: 760px;
    line-height: 24px;
    font-weight: 400;

    @media (max-width: 520px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const StyledSectionHeader = styled.div`
    text-align: center;
    margin-bottom: 64px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    ${StyledLongCopy} {
        margin-top: 24px;
        color: ${({ theme }) => theme.TextSecondary};
    }

    ${StyledHeadingTwo} {
        max-width: 920px;
    }

    ${StyledHeadingFour} {
        max-width: 920px;
        line-height: 40px;
    }

    @media (max-width: 840px) {
        text-align: left;
        align-items: flex-start;
    }

    @media (max-width: 560px) {
        margin-bottom: 46px;

        ${StyledHeadingTwo} {
            font-size: 24px;
            line-height: 30px;
        }

        ${StyledLongCopy} {
            font-size: 15px;
            margin-top: 16px;
            line-height: 22px;
        }
    }
`;

export const StyledSectionContent = styled.div`
    position: relative;
`;

export const StyledServiceCardsGrid = styled.div`
    display: grid;
    grid-gap: 24px;
    position: relative;
    grid-template-columns: repeat(3, minmax(70px, 1fr));

    @media (max-width: 960px) {
        grid-template-columns: repeat(2, minmax(70px, 1fr));
    }

    @media (max-width: 760px) {
        grid-template-columns: repeat(1, minmax(70px, 1fr));
    }
`;

export const StyledSnackbarIcon = styled.div`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const StyledSnackbarButton = styled.button`
    width: 20px;
    border: none;
    height: 20px;
    outline: none;
    flex-shrink: 0;
    margin-left: auto;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const StyledSnackbarWrapper = styled.div<{ status: SnackbarTypes }>`
    width: 100%;
    padding: 12px;
    position: relative;
    border-radius: 8px;
    background: ${({ theme }) => theme.BackgroundPrimary};
    border: 4px solid ${({ theme }) => theme.BorderPrimary};

    display: flex;
    align-items: flex-start;
    justify-content: center;

    color: ${({ theme }) => theme.TextPrimary};

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-right: 16px;
        display: inline-block;
    }

    ${({ status, theme }) => {
        switch (status) {
            case SnackbarTypes.INFO: {
                return css`
                    svg {
                        color: ${theme.BluePrimary};
                    }
                    border-color: ${theme.BlueTertiary};
                `;
            }
            case SnackbarTypes.SUCCESS: {
                return css`
                    svg {
                        color: ${theme.SuccessPrimary};
                    }
                    border-color: ${theme.SuccessTertiary};
                `;
            }
            case SnackbarTypes.ERROR: {
                return css`
                    svg {
                        color: ${theme.ErrorPrimary};
                    }
                    border-color: ${theme.ErrorTertiary};
                `;
            }
            case SnackbarTypes.WARNING: {
                return css`
                    svg {
                        color: ${theme.ErrorPrimary};
                    }
                    border-color: ${theme.ErrorTertiary};
                `;
            }
        }
    }}
`;

export const StyledFormGroupHeader = styled.div`
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.TextPrimary};
    line-height: 26px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        height: 26px;
        padding: 6px;
        font-size: 12px;
        color: ${({ theme }) => theme.BrandPrimary};
        line-height: 22px;
        border-radius: 20px;
        display: inline-block;
        text-transform: uppercase;
        border: 1px solid ${({ theme }) => theme.BrandPrimary};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const StyledBadge = styled.span`
    padding: 10px 13px;
    position: relative;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
    background: ${buttercup};
    vertical-align: baseline;

    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    text-transform: uppercase;
`;

// Consultation cards
export const StyledConsultationCard = styled.div`
    padding: 24px;
    position: relative;
    border-radius: 12px;
    transition: all 0.25s;
    background: ${({ theme }) => theme.BrandWhite};
    border: 1px solid ${({ theme }) => theme.BorderPrimary};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:hover {
        box-shadow: 0 12px 24px -12px rgba(16, 24, 40, 0.16);
    }
`;

export const StyledConsultationTitle = styled.h2`
    color: ${({ theme }) => theme.TextPrimary};
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 6px;
`;

export const StyledConsultationDescription = styled.p`
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.TextSecondary};
`;

export const StyledConsultationCardContent = styled.div`
    margin-bottom: 34px;
`;

export const StyledConsultationCardDeliveries = styled.div`
    margin-top: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledConsultationCardFooter = styled.div`
    width: 100%;
    margin-top: auto;

    ${StyledAnchorButton} {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
`;

export const StyledConsultationPriceLabel = styled.span`
    margin-right: 5px;
    color: ${({ theme }) => theme.TextPrimary};
    font-weight: 500;
`;

export const StyledConsultationPriceAmount = styled.span`
    color: ${({ theme }) => theme.TextPrimary};
    font-weight: 600;
`;

export const StyledConsultationPriceSupport = styled.span`
    margin-left: 4px;
    color: ${({ theme }) => theme.TextSecondary};
`;

export const StyledConsultationPrice = styled.div`
    margin: 4px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    padding: 4px 8px;
    line-height: 22px;
    border-radius: 22px;
    background: ${({ theme }) => theme.BlueSenary};
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledConsultationBadgeCell = styled.div`
    margin: 4px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    line-height: 22px;
    border-radius: 22px;

    span {
        display: block;
        padding: 4px 8px;
        border-radius: 6px;
    }

    &.delivery-cell {
        background: #009432;
        span {
            color: #009432;
            background: #e3ffec;
        }
    }

    &.e-script-cell {
        background: ${({ theme }) => theme.BlueSenary};
        span {
            color: ${({ theme }) => theme.BrandPrimary};
        }
    }
`;

export const StyledConsultationServicesGrid = styled.div`
    display: grid;
    grid-gap: 30px;
    overflow-y: auto;
    grid-template-columns: repeat(2, minmax(70px, 1fr));

    @media (max-width: 680px) {
        grid-template-columns: repeat(1, minmax(70px, 1fr));
    }
`;
