/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { AUTH_MANAGER_PATH } from '../../../api/endpoints';
import {
    LogoutEndpoint,
    PatientRegistrationEndpoint,
    PatientLoginEndpoint,
    PatientVerifySessionEndpoint,
    VerifyAccountEndpoint,
    RecoverAccountRequestEndpoint,
    VerifyRecoveryTokenEndpoint,
    ResetAccountPasswordEndpoint,
} from '../../../api/endpoints/auth';
import {
    LoginRequest,
    PatientRegistration,
    RecoverAccountRequest,
    ResetPassword,
    VerifyAccountRequest,
} from '../../../api/types/auth';
import { endpointFetch } from '../tools/fetch';

export function patientLogin(body: LoginRequest) {
    return endpointFetch(PatientLoginEndpoint, { scope: AUTH_MANAGER_PATH, body, redirect: false });
}

export function patientRegistration(body: PatientRegistration) {
    return endpointFetch(PatientRegistrationEndpoint, { scope: AUTH_MANAGER_PATH, body, redirect: false });
}

export function patientRecoverAccount(body: RecoverAccountRequest) {
    return endpointFetch(RecoverAccountRequestEndpoint, { scope: AUTH_MANAGER_PATH, body, redirect: false });
}

export function patientVerifyAccountRecoveryToken(token: string, verificationToken: string) {
    return endpointFetch(VerifyRecoveryTokenEndpoint, {
        scope: AUTH_MANAGER_PATH,
        redirect: false,
        params: {
            token,
            verificationToken,
        },
    });
}

export function patientResetAccountPassword(token: string, verificationToken: string, body: ResetPassword) {
    return endpointFetch(ResetAccountPasswordEndpoint, {
        scope: AUTH_MANAGER_PATH,
        redirect: false,
        body,
        params: {
            token,
            verificationToken,
        },
    });
}

export function patientAccountVerification(body: VerifyAccountRequest) {
    return endpointFetch(VerifyAccountEndpoint, { scope: AUTH_MANAGER_PATH, body, redirect: false });
}

export function patientSessionVerification() {
    return endpointFetch(PatientVerifySessionEndpoint, { scope: AUTH_MANAGER_PATH });
}

export function patientLogout() {
    return endpointFetch(LogoutEndpoint, { scope: AUTH_MANAGER_PATH });
}
