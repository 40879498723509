/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { v4 } from 'uuid';
import { FILE_UPLOAD_REFERENCE } from '../../../api/constants';
import { FILE_MANAGER_PATH } from '../../../api/endpoints';
import { DeleteAvatarEndpoint, UploadAvatarEndpoint, UploadReferencedFileEndpoint } from '../../../api/endpoints/file';
import { endpointFileFetch, endpointFetch } from '../tools/fetch';
import { ServiceRequestUpload } from '../redux/checkout';

export async function uploadReferenceFile(files: ServiceRequestUpload[]) {
    const uploadFormData = new FormData();

    for (const { file, filename } of files) {
        uploadFormData.append(FILE_UPLOAD_REFERENCE, file, filename);
    }

    return endpointFileFetch(UploadReferencedFileEndpoint, {
        scope: FILE_MANAGER_PATH,
        redirect: true,
        formData: uploadFormData,
    });
}

async function updateUserAvatar(imageUrl: string) {
    const croppedImage = await fetch(imageUrl).then((response) => response.blob());

    const uploadFormData = new FormData();
    uploadFormData.append(FILE_UPLOAD_REFERENCE, croppedImage, v4() + '.jpeg');

    return endpointFileFetch(UploadAvatarEndpoint, {
        scope: FILE_MANAGER_PATH,
        redirect: true,
        formData: uploadFormData,
    });
}

export async function deleteUserAvatar() {
    return endpointFetch(DeleteAvatarEndpoint, {
        scope: FILE_MANAGER_PATH,
        redirect: true,
    });
}
