/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createFamilyMedicalProfile,
    createOnboardingMedicalProfile,
    deleteMedicalProfile,
    getMedicalProfiles,
    updateMedicalProfile,
} from '../../../fetch/medical/profile';
import { MedicalProfileResource, MedicalProfile, MedicalProfileSchema } from '../../../../../api/types/medical/profile';
import { setSnackbarMessage } from '../../event';
import { SnackbarTypes } from '../../../types';
import { handleError, FetchError } from '../../../tools/fetch';

export const getMedicalProfilesThunk = createAsyncThunk<MedicalProfileResource[] | undefined>(
    'medical/get/profiles',
    async (_) => {
        try {
            const profiles = await getMedicalProfiles();
            return profiles;
        } catch (error) {
            const response = await handleError(error as FetchError);
            console.error('Error getting medical profiles: ', response);
        }
    },
);

export const createMedicalProfileThunk = createAsyncThunk<
    MedicalProfileResource | undefined,
    { profile: MedicalProfile; sessionId: string }
>('medical/create/profile/family', async ({ profile }, { dispatch, rejectWithValue }) => {
    try {
        const user = await createFamilyMedicalProfile(profile);
        return user;
    } catch (error) {
        const response = await handleError(error as FetchError);
        console.error('Medical profile error: ', error);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    'Something went wrong, we could not create your medical profile. Please try again later.',
            }),
        );
        return rejectWithValue(profile.first_name);
    }
});

export const createOnboardingMedicalProfileThunk = createAsyncThunk<void, MedicalProfile>(
    'medical/create/onboarding/profile',
    async (profile, { dispatch, rejectWithValue }) => {
        try {
            await createOnboardingMedicalProfile(profile);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        "We encountered an issue and couldn't create your medical profile. Please try again later.",
                }),
            );
            return rejectWithValue(profile.first_name);
        }
    },
);

export const updateMedicalProfileThunk = createAsyncThunk<
    void,
    { profileId: string; profile: MedicalProfile; sessionId: string }
>('medical/update/profile', async ({ profileId, profile }, { dispatch, rejectWithValue }) => {
    try {
        await updateMedicalProfile(profileId, profile);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    'Something went wrong, we could not update your medical profile. Please try again later.',
            }),
        );
        return rejectWithValue(profileId);
    }
});

export const deleteMedicalProfileThunk = createAsyncThunk<void, string>(
    'medical/delete/profile',
    async (profileId, { dispatch }) => {
        try {
            await deleteMedicalProfile(profileId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'Something went wrong, we could not delete your medical profile. Please try again later.',
                }),
            );
        }
    },
);
