/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 *
 * Description:
 * Using the following tool to generate our color names: http://chir.ag/projects/name-that-color/
 * Colors are documented with a comment describing their usage.
 */

/**
 * Primary brand green color
 */
export const purpleHeart = '#6941c6';

/**
 * Accent color for purple heart
 */
export const gigas = '#53389e';

/**
 * Light color for purple heart
 */
export const blueChalk = '#f4ebff';

/**
 * Secondary text color
 */
export const shuttleGray = '#475467';

/**
 * Borders primary color
 */
export const athensGray = '#e6e9ef';

/**
 * Borders alternative color
 */
export const mischka = '#d0d5dd';

/**
 * Borders secondary color
 */
export const wildSand = '#f3f3f4';

/**
 * Light grey color
 */
export const cornflowerBlue = '#f9fafb';

/**
 * Primary background color
 */
export const white = '#ffffff';

/**
 * Secondary background color
 */
export const zircon = '#F8F9FA';

/**
 * Tertiary background highlight color
 */
export const calabro = '#f8f9fa';

/**
 * Error color and highlights
 */
export const flamingo = '#f04438';

/**
 * Accent color - carnation
 */
export const redOrange = '#FF2929';

/**
 * Orange-yellowish color for special highlights
 */
export const buttercup = '#FFC312';

/**
 * Orange-yellowish color for background highlights
 */
export const spicedButter = '#ffda79';

/**
 * Orange-light yellow color for background highlights
 */
export const earlyDawn = '#FFF8E5';

/**
 * Orange color for special highlights
 */
export const carrotOrange = '#e58e26';

/**
 * Primary success color
 */
export const mountainMeadow = '#20c997';

/**
 * Primary success color highlights
 */
export const paradiseGreen = '#7bed9f';

/**
 * Alternate blue color.
 */
export const clearChill = '#1e90ff';

/**
 * Alternate blue color accent.
 */
export const brightGreek = '#3742fa';

/**
 * Blueish color for background highlights
 */
export const brightGreekAlt = '#c5e0ff';
