/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice } from '@reduxjs/toolkit';
import {
    patientLogoutThunk,
    patientRegistrationThunk,
    patientLoginThunk,
    verificationPatientAccountThunk,
    verifyPatientSessionThunk,
    patientRecoverAccountThunk,
    verifyAccountRecoveryTokenThunk,
} from './thunks';
import { PatientResource } from '../../../../api/types/user/patient';
import { createOnboardingMedicalProfileThunk } from '../medical/profile/thunk';
import { VerifyRecoveryTokenResponse } from '../../../../api/types/auth';

interface UserState {
    initials: string;
    fullName: string;
    account?: PatientResource;
    hasAuthenticationEvent: boolean;
    isVerifyingSessionEvent: boolean;
    isRecoveringAccountEvent: boolean;
    hasAccountVerificationEvent: boolean;
    recoveryTokenResponse?: VerifyRecoveryTokenResponse;
}

const initialState: UserState = {
    initials: '',
    fullName: '',
    hasAuthenticationEvent: false,
    isVerifyingSessionEvent: true,
    isRecoveringAccountEvent: false,
    hasAccountVerificationEvent: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetAuthEvent(draft) {
            draft.hasAuthenticationEvent = false;
        },
    },
    extraReducers: (reducers) => {
        reducers
            .addCase(patientLoginThunk.pending, (draft) => {
                draft.hasAuthenticationEvent = true;
            })
            .addCase(patientLoginThunk.fulfilled, (draft, { payload }) => {
                draft.account = payload;
                draft.initials = `${payload.first_name[0]}${payload.last_name[0]}`.toUpperCase();
                draft.fullName = `${payload.first_name} ${payload.last_name}`;
                draft.hasAuthenticationEvent = false;
            })
            .addCase(patientLoginThunk.rejected, (draft) => {
                draft.hasAuthenticationEvent = false;
            })
            .addCase(patientRegistrationThunk.pending, (draft) => {
                draft.hasAuthenticationEvent = true;
            })
            .addCase(patientRegistrationThunk.fulfilled, (draft, { payload }) => {
                if (payload == null) {
                    return;
                }

                draft.account = payload;
                draft.initials = `${payload.first_name[0]}${payload.last_name[0]}`.toUpperCase();
                draft.fullName = `${payload.first_name} ${payload.last_name}`;
                draft.hasAuthenticationEvent = false;
            })
            .addCase(patientRegistrationThunk.rejected, (draft) => {
                draft.hasAuthenticationEvent = false;
            })
            .addCase(verificationPatientAccountThunk.pending, (draft) => {
                draft.hasAccountVerificationEvent = true;
            })
            .addCase(verificationPatientAccountThunk.fulfilled, (draft) => {
                if (draft.account == null) {
                    return;
                }
                draft.account.verified = true;
                draft.hasAccountVerificationEvent = false;
            })
            .addCase(verificationPatientAccountThunk.rejected, (draft) => {
                draft.hasAccountVerificationEvent = false;
            })
            .addCase(createOnboardingMedicalProfileThunk.fulfilled, (draft) => {
                if (draft.account == null) {
                    return;
                }

                draft.account.completed_onboarding = true;
            })
            .addCase(verifyPatientSessionThunk.pending, (draft) => {
                // Set session verification status.
                draft.isVerifyingSessionEvent = true;
            })
            .addCase(verifyPatientSessionThunk.fulfilled, (draft, { payload }) => {
                draft.account = payload;
                draft.initials = `${payload.first_name[0]}${payload.last_name[0]}`.toUpperCase();
                draft.fullName = `${payload.first_name} ${payload.last_name}`;

                // Disable session verification status.
                draft.isVerifyingSessionEvent = false;
            })
            .addCase(verifyPatientSessionThunk.rejected, (draft) => {
                // Disable session verification status.
                draft.isVerifyingSessionEvent = false;
            })
            .addCase(patientLogoutThunk.pending, (draft) => {
                draft.hasAuthenticationEvent = false;
            })
            .addCase(patientLogoutThunk.fulfilled, (draft) => {
                delete draft.account;
            })
            // Account recovery thunk and state handlers.
            .addCase(patientRecoverAccountThunk.pending, (draft) => {
                draft.isRecoveringAccountEvent = true;
            })
            .addCase(patientRecoverAccountThunk.fulfilled, (draft) => {
                draft.isRecoveringAccountEvent = false;
            })
            .addCase(patientRecoverAccountThunk.rejected, (draft) => {
                draft.isRecoveringAccountEvent = false;
            })
            // Verify recovery token thunk and state handlers.
            .addCase(verifyAccountRecoveryTokenThunk.fulfilled, (draft, { payload }) => {
                draft.recoveryTokenResponse = payload;
            });
    },
});

export const userReducer = userSlice.reducer;

export const { resetAuthEvent } = userSlice.actions;
