/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { EmailAddressSchema } from '.';
import { CustomerSupportQuestionTypeSchema } from '../constants';

export const SubmitContactFormSchema = r.Record({
    name: r.String,
    message: r.String,
    email: EmailAddressSchema,
    form_type: CustomerSupportQuestionTypeSchema,
});
export type SubmitContactForm = r.Static<typeof SubmitContactFormSchema>;
