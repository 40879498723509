/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    MedicineConsultation,
    PrescriptionConsultDrug,
    PrescriptionConsultFamilyResource,
} from '../../../../api/types/prescription';
import {
    getPrescriptionMedicineConsultationThunk,
    getPrescriptionFamiliesThunk,
    getPrescriptionFamilyMedicineThunk,
    searchPrescriptionMedicineThunk,
} from './thunk';
import { ResourceSessionStatus } from '../../types';

interface PrescriptionState {
    hasNoSearchResults: boolean;
    selectedFamilyId?: string;
    medicineSearchTerm: string;
    isLoadingFamilies: boolean;
    isSearchingForMedicine: boolean;
    medicine: PrescriptionConsultDrug[];
    medicineConsultation?: MedicineConsultation;
    prescriptionMedicine?: PrescriptionConsultDrug;
    families: PrescriptionConsultFamilyResource[];
    familyMedicine: Record<string, PrescriptionConsultDrug[]>;
    medicineRequestEvent: Record<string, ResourceSessionStatus>;
    consultationsRequestEvent: Record<string, ResourceSessionStatus>;
}

const initialState: PrescriptionState = {
    medicine: [],
    families: [],
    familyMedicine: {},
    medicineSearchTerm: '',
    hasNoSearchResults: false,
    isLoadingFamilies: false,
    medicineRequestEvent: {},
    isSearchingForMedicine: false,
    consultationsRequestEvent: {},
};

const prescriptionSlice = createSlice({
    name: 'prescription',
    initialState,
    reducers: {
        resetSearchParameter(draft) {
            if (draft.selectedFamilyId == null) {
                draft.medicine = [];
            }
        },
        resetPrescriptionMedicine(draft) {
            draft.medicine = [];
            draft.hasNoSearchResults = false;
        },
    },
    extraReducers: (reducers) => {
        reducers
            // Families thunk handlers.
            .addCase(getPrescriptionFamiliesThunk.pending, (draft) => {
                draft.isLoadingFamilies = true;
            })
            .addCase(getPrescriptionFamiliesThunk.fulfilled, (draft, { payload }) => {
                draft.families = payload;
                draft.isLoadingFamilies = false;
            })
            .addCase(getPrescriptionFamiliesThunk.rejected, (draft) => {
                draft.isLoadingFamilies = false;
            })
            // Families medicine thunk handlers.
            .addCase(getPrescriptionFamilyMedicineThunk.pending, (draft, { meta }) => {
                draft.medicineRequestEvent[meta.arg] = ResourceSessionStatus.IN_PROGRESS;
                draft.selectedFamilyId = meta.arg;
            })
            .addCase(getPrescriptionFamilyMedicineThunk.fulfilled, (draft, { meta, payload }) => {
                draft.familyMedicine[meta.arg] = payload;
                if (draft.selectedFamilyId === meta.arg) {
                    draft.medicine = payload;
                }

                delete draft.medicineRequestEvent[meta.arg];
            })
            .addCase(getPrescriptionFamilyMedicineThunk.rejected, (draft, { meta }) => {
                delete draft.medicineRequestEvent[meta.arg];
            })
            // Search medicine thunk handlers.
            .addCase(searchPrescriptionMedicineThunk.pending, (draft, { meta }) => {
                draft.hasNoSearchResults = false;
                draft.isSearchingForMedicine = true;
                draft.medicineSearchTerm = meta.arg.searchParameter.trim();
            })
            .addCase(searchPrescriptionMedicineThunk.fulfilled, (draft, { meta, payload }) => {
                if (meta.arg.searchParameter !== draft.medicineSearchTerm) {
                    return;
                }

                draft.medicine = payload;
                draft.isSearchingForMedicine = false;
                draft.hasNoSearchResults = payload.length === 0 && draft.medicineSearchTerm.length > 0;
            })
            .addCase(searchPrescriptionMedicineThunk.rejected, (draft, { meta }) => {
                if (meta.arg.searchParameter !== draft.medicineSearchTerm) {
                    return;
                }

                draft.hasNoSearchResults = false;
                draft.isSearchingForMedicine = false;
            })
            // Medicine digital consultation handlers.
            .addCase(getPrescriptionMedicineConsultationThunk.pending, (draft, { meta }) => {
                draft.medicineRequestEvent[meta.arg] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(getPrescriptionMedicineConsultationThunk.fulfilled, (draft, { meta, payload }) => {
                draft.medicineConsultation = payload;
                delete draft.medicineRequestEvent[meta.arg];
            })
            .addCase(getPrescriptionMedicineConsultationThunk.rejected, (draft, { meta }) => {
                delete draft.medicineRequestEvent[meta.arg];
            });
    },
});

export const prescriptionReducer = prescriptionSlice.reducer;

export const { resetPrescriptionMedicine, resetSearchParameter } = prescriptionSlice.actions;
