/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export enum AuthRoutes {
    Login = 'login',
    Register = 'register',
    Recovery = 'recovery',
    Verification = 'verification',
}
