/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

// Protected route properties contract.
export interface ProtectedRouteProps {
    element: JSX.Element;
}

export enum SnackbarTypes {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export enum ResourceSessionStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN PROGRESS',
}

export const CONSULTATION_NOTE_TEMPLATE = `History of presenting complaint:

Relevant PMHx:

Impression:

Plan:
`;

export interface FrequentlyAskedQuestion {
    question: string;
    answer: string;
}
