/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatientMedicalRequest } from '../../../../../api/types/medical/request';
import { getMedicalRequestRecords } from '../../../fetch/medical/request';

export const getMedicalRequestThunk = createAsyncThunk<PatientMedicalRequest[] | undefined, void>(
    'medical-request/records',
    async () => {
        try {
            const records = await getMedicalRequestRecords();
            return records;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error getting medical requests: ', error);
        }
    },
);
