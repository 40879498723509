/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { Tooltip, IconButton, Avatar, Menu } from '@mui/material';
import {
    IconSettings,
    IconLogout,
    IconUsers,
    IconCards,
    IconMenu2,
    IconFileCertificate,
    IconMicroscope,
    IconStethoscope,
    IconUserShare,
    IconHome2,
    IconPrescription,
    IconScaleOutline,
} from '@tabler/icons-react';
import { FC, useState } from 'react';
import {
    StyledNavigationTopBarOptions,
    StyledUserMenuContainer,
    StyledUserMenuHeader,
    StyledUserMenuName,
    StyledUserMenuEmail,
    StyledUserMenuBody,
    StyledAccountNavButton,
    StyledAccountMenuButton,
    StyledMobileNavigation,
    StyledAccountNavDivider,
    StyledUserMenuHeaderDetails,
} from './../style';
import { useDispatch, useSelector } from 'react-redux';
import { patientLogoutThunk } from '../../../../redux/patient/thunks';
import { AppDispatch } from '../../../../store';
import { ConsultationRoutes, PatientDashboardRoutes } from '../../../../../../api/routes/consultation';
import {
    selectUserEmail,
    selectUserAvatar,
    selectUserInitials,
    selectUserFullName,
} from '../../../../redux/patient/selectors';

export const UserNavigation: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorElement);

    // Get authorized user account details.
    const userEmail = useSelector(selectUserEmail);
    const userAvatar = useSelector(selectUserAvatar);
    const userInitials = useSelector(selectUserInitials);
    const userFullname = useSelector(selectUserFullName);

    const handleCloseNavigationMenu = () => {
        setMenuAnchorElement(null);
    };

    const handleLogoutRequest = () => {
        dispatch(patientLogoutThunk());
        handleCloseNavigationMenu();
    };

    return (
        <StyledNavigationTopBarOptions>
            <Tooltip title="Navigation Menu">
                <StyledMobileNavigation
                    className="animate__animated animate__slideInRight"
                    type="button"
                    onClick={(event) => setMenuAnchorElement(event.currentTarget)}
                    aria-controls={isMenuOpen ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                >
                    <IconButton className="patient-profile-avatar" size="small" sx={{ ml: 2 }}>
                        <Avatar sx={{ width: 46, height: 46, position: 'relative' }} src={userAvatar ?? undefined}>
                            {userInitials}
                        </Avatar>
                    </IconButton>

                    <IconMenu2 />
                </StyledMobileNavigation>
            </Tooltip>
            <Menu
                id="account-menu"
                anchorEl={menuAnchorElement}
                open={isMenuOpen}
                onClose={() => setMenuAnchorElement(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{
                    '& .MuiMenu-list': {
                        padding: '0 !important',
                    },
                    '& .MuiMenu-paper': {
                        overflow: 'hidden',
                        borderRadius: '14px',
                        boxShadow: '0 1rem 3rem rgba(0,0,0,.16)',
                    },
                }}
            >
                <StyledUserMenuContainer>
                    <StyledUserMenuBody>
                        <StyledUserMenuHeader>
                            <Avatar sx={{ width: 46, height: 46, position: 'relative' }} src={userAvatar ?? undefined}>
                                {userInitials}
                            </Avatar>
                            <StyledUserMenuHeaderDetails>
                                <StyledUserMenuName>{userFullname}</StyledUserMenuName>
                                <StyledUserMenuEmail>{userEmail}</StyledUserMenuEmail>
                            </StyledUserMenuHeaderDetails>
                        </StyledUserMenuHeader>

                        <StyledAccountNavDivider />

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconHome2 />
                            <span>Home</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Telehealth}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconStethoscope />
                            <span>Telehealth</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Prescriptions}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconPrescription />
                            <span>Online Prescriptions</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.MedicalCertificates}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconFileCertificate />
                            <span>Medical Certificates</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.WeightManagementConsultation}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconScaleOutline />
                            <span>Weight Management</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Pathology}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconMicroscope />
                            <span>Blood Tests</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Referrals}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconUserShare />
                            <span>Referrals</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavDivider />

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.Profile}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconSettings />
                            <span>My Account</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.MedicalProfiles}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconUsers />
                            <span>Medical Profiles</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavButton
                            to={`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.MedicalRequests}`}
                            onClick={handleCloseNavigationMenu}
                        >
                            <IconCards />
                            <span>Medical Requests</span>
                        </StyledAccountNavButton>

                        <StyledAccountNavDivider />

                        <StyledAccountMenuButton type="button" onClick={handleLogoutRequest}>
                            <IconLogout />
                            <span>Logout</span>
                        </StyledAccountMenuButton>
                    </StyledUserMenuBody>
                </StyledUserMenuContainer>
            </Menu>
        </StyledNavigationTopBarOptions>
    );
};
