/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import styled, { keyframes } from 'styled-components';
import { purpleHeart, athensGray, white } from '../../../style/color';
import { flexContainerStyle } from '../../../style';

const spinnerKeyframes = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const StyledLoadingPlaceholder = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: ${({ theme }) => theme.BrandWhite};

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledSpinner = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    border: 5px solid ${({ theme }) => theme.BorderPrimary};
    border-bottom-color: ${({ theme }) => theme.BrandPrimary};
    animation: ${spinnerKeyframes} 1s linear infinite;
`;
