/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { RootState } from '../../store';

export const selectPlatformState = (state: RootState) => state.platform;

export const selectHasInitializedTracking = (state: RootState) => state.platform.hasInitializedTracking;

export const selectThemeType = (state: RootState) => state.platform.theme;

export const selectIsPlatformDetailsInitialized = (state: RootState) => state.platform.initialized;

export const selectPaypalPublicKey = (state: RootState) => state.platform?.paypalPublicKey;
