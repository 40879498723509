/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice } from '@reduxjs/toolkit';
import { completeStripePaymentThunk } from '../../checkout/thunk';
import { getMedicalRequestThunk } from './thunk';
import { PatientMedicalRequest } from '../../../../../api/types/medical/request';

export interface IMedicalRequests {
    fetchedRecords: boolean;
    requests: PatientMedicalRequest[];
}

const initialState: IMedicalRequests = {
    requests: [],
    fetchedRecords: false,
};

const medicalRequestsSlice = createSlice({
    name: 'medical-requests',
    initialState,
    reducers: {},
    extraReducers(reducers) {
        reducers
            .addCase(completeStripePaymentThunk.fulfilled, (draft, { payload }) => {
                if (payload != null) {
                    draft.requests.push(payload);
                }
            })
            .addCase(getMedicalRequestThunk.fulfilled, (draft, { payload }) => {
                if (payload != null) {
                    draft.requests = payload;
                    draft.fetchedRecords = true;
                }
            });
    },
});

export const medicalRequestsReducer = medicalRequestsSlice.reducer;
