/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResourceSessionStatus, SnackbarTypes } from '../../types';
interface IEventState {
    snackbar?: {
        message: string;
        type: SnackbarTypes;
    };
    requests: Record<string, ResourceSessionStatus>;
}

const initialState: IEventState = {
    requests: {},
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventRequest(draft, { payload }: PayloadAction<{ eventId: string; status: ResourceSessionStatus }>) {
            draft.requests[payload.eventId] = payload.status;
        },
        removeEventRequest(draft, { payload }: PayloadAction<string>) {
            if (draft.requests[payload] != null) {
                delete draft.requests[payload];
            }
        },
        setSnackbarMessage(draft, { payload }: PayloadAction<{ message: string; type: SnackbarTypes }>) {
            draft.snackbar = payload;
        },
        resetSnackbarMessage(draft) {
            delete draft.snackbar;
        },
    },
    extraReducers: (reducers) => {
        reducers;
        // .addCase(createInfirmaryRequestFlagThunk.fulfilled, (draft, { payload }) => {
        //     if (draft.requests[payload.id] != null) {
        //         draft.requests[payload.id] = ResourceSessionStatus.COMPLETED;
        //     }
        // })
        // .addCase(createInfirmaryRequestFlagThunk.rejected, (draft, { meta }) => {
        //     draft.requests[meta.arg.id] = ResourceSessionStatus.PENDING;
        // })
        // .addCase(createInfirmaryRequestNoteThunk.fulfilled, (draft, { payload }) => {
        //     if (payload != null && draft.requests[payload.id] != null) {
        //         draft.requests[payload.id] = ResourceSessionStatus.COMPLETED;
        //     }
        // })
        // .addCase(createInfirmaryRequestNoteThunk.rejected, (draft, { meta }) => {
        //     if (draft.requests[meta.arg.id] != null) {
        //         draft.requests[meta.arg.id] = ResourceSessionStatus.PENDING;
        //     }
        // });
    },
});

export const eventReducer = eventSlice.reducer;

export const { removeEventRequest, setEventRequest, resetSnackbarMessage, setSnackbarMessage } = eventSlice.actions;
