/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { completeStripePaymentThunk } from './thunk';
import { MedicalRequestCheckout } from '../../../../api/types/medical/request';

export interface ServiceRequestUpload {
    file: File;
    filename: string;
}

export interface ServiceCheckout {
    transactionInProgress: boolean;
    request?: MedicalRequestCheckout;
    requestUploads: ServiceRequestUpload[];
}

const initialState: ServiceCheckout = {
    transactionInProgress: false,
    requestUploads: [],
};

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setCheckoutRequest(
            draft,
            { payload }: PayloadAction<{ request: MedicalRequestCheckout; requestUploads: ServiceRequestUpload[] }>,
        ) {
            draft.request = payload.request;
            draft.transactionInProgress = false;
            draft.requestUploads = payload.requestUploads;
        },
        clearCheckoutRequest(draft) {
            delete draft.request;
            draft.requestUploads = [];
            draft.transactionInProgress = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(completeStripePaymentThunk.pending, (draft) => {
                draft.transactionInProgress = true;
            })
            .addCase(completeStripePaymentThunk.fulfilled, (draft) => {
                delete draft.request;
                draft.requestUploads = [];
                draft.transactionInProgress = false;
            })
            .addCase(completeStripePaymentThunk.rejected, (draft) => {
                draft.transactionInProgress = false;
            });
    },
});

export const checkoutReducer = checkoutSlice.reducer;

export const { setCheckoutRequest, clearCheckoutRequest } = checkoutSlice.actions;
