/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MedicalProfileResource } from '../../../../../api/types/medical/profile';
import {
    createMedicalProfileThunk,
    createOnboardingMedicalProfileThunk,
    deleteMedicalProfileThunk,
    getMedicalProfilesThunk,
    updateMedicalProfileThunk,
} from './thunk';
import { ResourceSessionStatus } from '../../../../../api/constants';

interface MedicalProfileState {
    profiles: MedicalProfileResource[];
    isCreatingOnboardingProfile: boolean;
    events: Record<number | string, ResourceSessionStatus>;
}

const initialState: MedicalProfileState = {
    events: {},
    profiles: [],
    isCreatingOnboardingProfile: false,
};

const medicalProfileSlice = createSlice({
    name: 'medical-profile',
    initialState,
    reducers: {
        setMedicalProfileEvent(
            draft,
            { payload }: PayloadAction<{ id: number | string; status: ResourceSessionStatus }>,
        ) {
            draft.events[payload.id] = payload.status;
        },
        deleteMedicalProfileEvent(draft, { payload }: PayloadAction<number | string>) {
            delete draft.events[payload];
        },
    },
    extraReducers: (reducers) => {
        reducers
            .addCase(getMedicalProfilesThunk.fulfilled, (draft, { payload }) => {
                if (payload == null) {
                    return;
                }

                draft.profiles = payload;
            })
            .addCase(createMedicalProfileThunk.fulfilled, (draft, { payload, meta }) => {
                if (payload == null) {
                    return;
                }

                draft.profiles.push(payload);
                draft.events[meta.arg.sessionId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(createMedicalProfileThunk.rejected, (draft, { meta }) => {
                draft.events[meta.arg.sessionId] = ResourceSessionStatus.PENDING;
            })
            .addCase(updateMedicalProfileThunk.fulfilled, (draft, { meta }) => {
                draft.events[meta.arg.sessionId] = ResourceSessionStatus.COMPLETED;

                const profileIndex = draft.profiles.findIndex(({ id }) => id === meta.arg.profileId);
                if (profileIndex !== -1) {
                    draft.profiles[profileIndex] = {
                        ...draft.profiles[profileIndex],
                        ...meta.arg.profile,
                    };
                }
            })
            .addCase(updateMedicalProfileThunk.rejected, (draft, { meta }) => {
                draft.events[meta.arg.sessionId] = ResourceSessionStatus.PENDING;
            })
            .addCase(deleteMedicalProfileThunk.fulfilled, (draft, { meta }) => {
                draft.profiles = draft.profiles.filter(({ id }) => id !== meta.arg);
            })
            .addCase(createOnboardingMedicalProfileThunk.pending, (draft, { meta }) => {
                draft.isCreatingOnboardingProfile = true;
            })
            .addCase(createOnboardingMedicalProfileThunk.fulfilled, (draft, { meta }) => {
                draft.isCreatingOnboardingProfile = false;
            })
            .addCase(createOnboardingMedicalProfileThunk.rejected, (draft, { meta }) => {
                draft.isCreatingOnboardingProfile = false;
            });
    },
});

export const medicalProfileReducer = medicalProfileSlice.reducer;
export const { deleteMedicalProfileEvent, setMedicalProfileEvent } = medicalProfileSlice.actions;
