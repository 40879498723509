/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
import { push } from 'redux-first-history';
import { onCompletePaypalCheckout, onCompleteStripeCheckout } from '../../fetch/checkout';
import { PaypalCheckoutPayment, StripeCheckoutPayment, StripePayment } from '../../../../api/types/checkout';
import { PatientMedicalRequest } from '../../../../api/types/medical/request';
import { COMMON_RESTFUL_ERROR_MESSAGE } from '../../../../api/constants';
import { setSnackbarMessage } from '../event';
import { SnackbarTypes } from '../../types';
import { uploadReferenceFile } from '../../fetch/file';
import { FetchError, handleError } from '../../tools/fetch';
import { RootState } from '../../store';
import { ConsultationRoutes, PatientDashboardRoutes } from '../../../../api/routes/consultation';
import { getMedicalRequestThunk } from '../medical/request/thunk';
import { gtag } from 'ga-gtag';

export const completeStripePaymentThunk = createAsyncThunk<
    PatientMedicalRequest,
    StripePayment,
    { rejectValue: string; state: RootState }
>('checkout/stripe/payment', async (payment, { dispatch, rejectWithValue, getState }) => {
    let isCheckoutSuccessful = false;
    try {
        const { checkout } = getState();

        if (checkout.request == null) {
            dispatch(push(`/${ConsultationRoutes.Patient}`));
            throw new Error('We encountered a problem and could not complete your checkout. Please try again.`');
        }

        const body: StripeCheckoutPayment = {
            payment,
            requestUploadReferenceFiles: [],
            ...checkout.request,
        };

        // Upload consultation uploads if provided.
        if (checkout.requestUploads.length > 0) {
            // Updated request upload reference identification.
            body.requestUploadReferenceFiles = await uploadReferenceFile(checkout.requestUploads);
        }

        const medicalRequest = await onCompleteStripeCheckout(body);

        // Event snippet for Begin checkout conversion page.
        if (process.env.NODE_ENV === 'production') {
            try {
                gtag('event', 'conversion', {
                    send_to: 'AW-11117238332/Vcq5CLXvkZIYELywjrUp',
                    transaction_id: medicalRequest.id,
                });
            } catch (error) {
                console.log('[GTM] An error occurred using the Checkout GTM Manager: ', error);
            }
        }

        isCheckoutSuccessful = true;

        // Redirect the user to their medical requests table.
        dispatch(push(`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.MedicalRequests}`));

        dispatch(
            setSnackbarMessage({
                message: `Thank you so much for your ${checkout.request.service.title} request. A certified practitioner will be reviewing your request in a few minutes.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return medicalRequest;
    } catch (error) {
        console.log('Error: ', error);
        const response = await handleError(error as FetchError);

        // Log the error for debugging.
        captureMessage(response?.reason ?? `[PATIENT DASHBOARD] Failed to complete patient checkout. Error: ${error}`);

        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        return rejectWithValue(response?.reason);
    } finally {
        if (isCheckoutSuccessful) {
            dispatch(getMedicalRequestThunk());
        }
    }
});

export const completePaypalPaymentThunk = createAsyncThunk<
    PatientMedicalRequest,
    string,
    { rejectValue: string; state: RootState }
>('checkout/paypal/payment', async (orderId, { dispatch, rejectWithValue, getState }) => {
    let isCheckoutSuccessful = false;
    try {
        const { checkout } = getState();

        if (checkout.request == null) {
            dispatch(push(`/${ConsultationRoutes.Patient}`));
            throw new Error('We encountered a problem and could not complete your checkout. Please try again.`');
        }

        const body: PaypalCheckoutPayment = {
            orderId,
            requestUploadReferenceFiles: [],
            ...checkout.request,
        };

        // Upload consultation uploads if provided.
        if (checkout.requestUploads.length > 0) {
            // Updated request upload reference identification.
            body.requestUploadReferenceFiles = await uploadReferenceFile(checkout.requestUploads);
        }

        const medicalRequest = await onCompletePaypalCheckout(body);

        // Event snippet for Begin checkout conversion page.
        if (process.env.NODE_ENV === 'production') {
            try {
                gtag('event', 'conversion', {
                    send_to: 'AW-11117238332/Vcq5CLXvkZIYELywjrUp',
                    transaction_id: medicalRequest.id,
                });
            } catch (error) {
                console.log('[GTM] An error occurred using the Checkout GTM Manager: ', error);
            }
        }

        isCheckoutSuccessful = true;

        // Redirect the user to their medical requests table.
        dispatch(push(`/${ConsultationRoutes.Patient}/${PatientDashboardRoutes.MedicalRequests}`));

        dispatch(
            setSnackbarMessage({
                message: `Thank you so much for your ${checkout.request.service.title} request. A certified practitioner will be reviewing your request in a few minutes.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return medicalRequest;
    } catch (error) {
        const response = await handleError(error as FetchError);

        // Log the error for debugging.
        captureMessage(response?.reason ?? `[PATIENT DASHBOARD] Failed to complete patient checkout. Error: ${error}`);

        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        return rejectWithValue(response?.reason);
    } finally {
        if (isCheckoutSuccessful) {
            dispatch(getMedicalRequestThunk());
        }
    }
});
