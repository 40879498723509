/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 *
 * Description:
 * Defines global styling for the application. Creating this as a component
 * makes it easy to share the styles with Storybook for UI elements testing and creating.
 *
 * The component makes use of the styled-components createGlobalStyle, used thought the application.
 */

import { createGlobalStyle } from 'styled-components';
import { flamingo } from './color';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html,
    body {
        color: ${({ theme }) => theme.TextPrimary};
        font-size: 16px;
        font-weight: 400;
        position: relative;
        background: ${({ theme }) => theme.BrandWhite};
        text-rendering: optimizeLegibility;
        font-family: 'Inter', sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-variation-settings: "slnt" 0;
    }

    body {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto !important;
        padding: 0 !important;
    }

    a {
        cursor: pointer;
        text-decoration: none;
    }

    li {
        list-style: none;
    }
    
    button {
        cursor: pointer;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* Margin and Padding Rules */
    .margin-top-80 {
        margin-top: 80px;
    }

    .margin-top-100 {
        margin-top: 100px;
    }

    .margin-bottom-80 {
        margin-bottom: 80px;
    }

    .margin-bottom-100 {
        margin-bottom: 100px;
    }

    .margin-top-60 {
        margin-top: 60px;
    }

    .margin-bottom-60 {
        margin-bottom: 60px;
    }

    .margin-top-40 {
        margin-top: 40px;
    }

    .margin-bottom-40 {
        margin-bottom: 40px;
    }

    .margin-top-30 {
        margin-top: 30px;
    }

    .margin-bottom-30 {
        margin-bottom: 30px;
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .margin-bottom-20 {
        margin-bottom: 20px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .margin-bottom-10 {
        margin-bottom: 10px;
    }

    .margin-top-5 {
        margin-top: 5px;
    }

    .margin-bottom-5 {
        margin-bottom: 5px;
    }

    .service-confirmation-button a {
        width: 100%;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.BrandPrimary} !important;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: ${flamingo} !important;
    }

    .MuiPaper-elevation,
    .MuiAutocomplete-popper {
        outline: none;
        overflow: hidden;
        border-radius: 12px !important;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.16) !important;
        border: 1px solid ${({ theme }) => theme.BorderPrimary} !important;
    }

    .MuiMenu-paper {
        border-radius: 12px !important;
    }


    .MuiInputBase-formControl {
        position: relative;
        background: ${({ theme }) => theme.BackgroundPrimary};
    }

    fieldset,
    legend {
        display: none !important;
    }

    .StripeElement,
    .StripeElementIdeal,
    .StripeElementP24,
    .StripeElementEPS,
    .StripeElementFpx {
      display: block;
      margin: 10px 0 20px 0;
      max-width: 500px;
      padding: 10px 14px;
      border-radius: 4px;
      background: white;
      border: 1px solid ${({ theme }) => theme.BorderPrimary};
    }
    
    .StripeElement--focus,
    .StripeElementIdeal--focus,
    .StripeElementP24--focus,
    .StripeElementEPS--focus,
    .StripeElementFpx--focus {
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }
    
    .StripeElement.loading {
      height: 41.6px;
      opacity: 0.6;
    }
    
    .StripeElementIdeal,
    .StripeElementP24,
    .StripeElementEPS,
    .StripeElementFpx {
      padding: 0;
    }

    .MuiPopover-paper {
        border: 1px solid ${({ theme }) => theme.BorderPrimary} !important;
    }

    .mobile-navigation-addition-options-menu .MuiPaper-elevation {
        margin-top: 0 !important;
        transform: translateY(-46px) !important;
    }
`;
