/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    MedicineConsultation,
    PrescriptionConsultDrug,
    PrescriptionConsultFamilyResource,
} from '../../../../api/types/prescription';
import {
    getPrescriptionDigitalConsultation,
    getPrescriptionFamilies,
    getPrescriptionFamilyMedicine,
    searchPrescriptionMedicine,
} from '../../fetch/prescription';
import { FetchError, handleError } from '../../tools/fetch';
import { setSnackbarMessage } from '../event';
import { SnackbarTypes } from '../../types';
import { DigitalConsultationRecord } from '../../../../api/types/consultation';

export const getPrescriptionFamiliesThunk = createAsyncThunk<PrescriptionConsultFamilyResource[]>(
    'prescription/get/families',
    async (_, { rejectWithValue }) => {
        try {
            return await getPrescriptionFamilies();
        } catch (error) {
            const response = await handleError(error as FetchError);
            return rejectWithValue(response?.reason);
        }
    },
);

export const getPrescriptionFamilyMedicineThunk = createAsyncThunk<PrescriptionConsultDrug[], string>(
    'prescription/family/medicine',
    async (prescriptionFamilyId, { rejectWithValue, dispatch }) => {
        try {
            return await getPrescriptionFamilyMedicine(prescriptionFamilyId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'We encountered an error while getting requested medicine. Please try again later.',
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const getPrescriptionMedicineConsultationThunk = createAsyncThunk<MedicineConsultation, string>(
    'prescription/medicine/digital/consultation',
    async (prescriptionMedicineId, { rejectWithValue, dispatch }) => {
        try {
            return await getPrescriptionDigitalConsultation(prescriptionMedicineId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'We encountered an error while processing your prescription request. Please try again later.',
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const searchPrescriptionMedicineThunk = createAsyncThunk<
    PrescriptionConsultDrug[],
    {
        searchParameter: string;
        medicineFamily?: string;
    }
>('prescription/search/medicine', async ({ searchParameter, medicineFamily }, { rejectWithValue, dispatch }) => {
    try {
        return await searchPrescriptionMedicine(searchParameter, medicineFamily);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    'We encountered an error while searching for your requested medicine. Please try again later.',
            }),
        );
        return rejectWithValue(response?.reason);
    }
});
