/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { MEDICAL_REQUEST_MANAGER_PATH } from '../../../../api/endpoints';
import { GetMedicalRequestsEndpoint } from '../../../../api/endpoints/medical/request';
import { endpointFetch } from '../../tools/fetch';

export function getMedicalRequestRecords() {
    return endpointFetch(GetMedicalRequestsEndpoint, { scope: MEDICAL_REQUEST_MANAGER_PATH });
}
