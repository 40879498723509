/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { FC, PropsWithChildren } from 'react';
import { CommonButtonProps } from '../style';
import { StyledAnchorButton } from './style';

export interface AnchorButtonProps extends CommonButtonProps {
    path: string;
}

export const AnchorButton: FC<PropsWithChildren<AnchorButtonProps>> = ({
    children,
    variation,
    disabled = false,
    path,
}) => {
    return (
        <StyledAnchorButton to={path} variation={variation} disabled={disabled}>
            {children}
        </StyledAnchorButton>
    );
};
