/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice } from '@reduxjs/toolkit';
import { getPlatformDetailsThunk } from './thunk';
import { ThemeType } from '../../../../api/constants';

interface PlatformState {
    theme: ThemeType;
    initialized: boolean;
    hasInitializedTracking: boolean;
    paypalPublicKey?: string;
    stripePublicKey?: string;
}

const initialState: PlatformState = {
    initialized: false,
    hasInitializedTracking: false,
    theme: ThemeType.LightTheme,
};

const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {},
    extraReducers: (reducers) => {
        reducers.addCase(getPlatformDetailsThunk.fulfilled, (_, { payload }) => {
            // draft.stripePublicKey = payload.stripe.publicKey;
            // draft.paypalPublicKey = payload.paypal.publicKey;
            // draft.initialized = true;
        });
    },
});

export const platformReducer = platformSlice.reducer;
